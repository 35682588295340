<template>
  <div id="singleGene">
    <div class="container">
      <div style="margin-bottom: 1rem;">
        <h1>Sample Select</h1>
      </div>
      <a-collapse default-active-key="1" :bordered="false">
        <template #expandIcon="props">
          <a-icon type="caret-right" style="font-size: 18px" :rotate="props.isActive ? 90 : 0"/>
        </template>
        <a-collapse-panel v-for="item in Object.keys(fieldObj)" :key="item"
                          :header="item === 'main_tissues' ? 'Tissues' : item.charAt(0).toUpperCase() + item.slice(1)">
          <a-tree
              v-model="params[item]"
              checkable
              :tree-data="getTreeData(fieldObj[item],item)"
          />
        </a-collapse-panel>
      </a-collapse>

      <div style="margin-bottom: 1rem;">
        <h1>Gene Select</h1>
      </div>
      <a-spin :spinning="spinning" class="geneSelectSpin">
        <a-select class="geneSelect" dropdownClassName="geneSelectDrop"
                  show-search v-model="genes" placeholder="input search text"
                  :default-active-first-option="false"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  :show-arrow="false"
                  :filter-option="false"
                  allow-clear
                  @popupScroll="genesPopupScroll"
                  @search="geneKeydown"
                  @change="geneChange">
          <a-select-option v-for="gene in genesArr" :key="gene.symbol" :value="gene.ensemble_id">
            {{ gene.symbol }}
          </a-select-option>
        </a-select>
      </a-spin>

      <div style="margin: 1rem 0;">
        <h1>Sample Feature Select</h1>
      </div>
      <a-spin :spinning="groupSpin" class="geneSelectSpin">
        <a-select class="geneSelect" v-model="parameterFiled" dropdownClassName="geneSelectDrop" show-search
                  :default-active-first-option="false"
                  placeholder="input search text"
                  :show-arrow="false" allow-clear
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <!--                    <a-select-option v-for="c in parameterFiledArr" :key="c" v-if="!common.isNotShowColumns(c)">-->
          <a-select-option v-for="c in parameterFiledArr" :key="c" :value="c">
            {{ c.slice(0, 1).toUpperCase() + c.slice(1).toLowerCase() }}
          </a-select-option>
        </a-select>
      </a-spin>

      <div class="btn">
        <a-button class="baseBtn"
                  :loading="queryLoading"
                  @click="query()">Search
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import {fetchFieldValues, fetchSampleFields, listDetail} from "@/request/niu_api";
import {sample_list} from "@/request/api";
import {submit_ea} from "@/request/task";
import {syncTaskListStorage} from "@/utils";
import {waitResponseTask} from "@/utils/storage";
import Bus from "@/utils/bus";

export default {
  name: "SingleGene",
  data() {
    return {
      /* 基因/转录本 */
      genesArr: [], //基因总数据
      genes: "", //单基因
      geneOption: {},
      searchValue: "",
      spinning: true,
      groupSpin: true,
      genePage: 1,
      geneLimit: 100,
      geneTotal: 0,

      /* 样本选择 */
      dataSource: [],
      parameterFiled: "",
      parameterFiledArr: [], //分组属性键数组
      /* 样本选择 end */
      queryLoading: false,

      /* 2023-7-10修改 */
      fieldObj: {},
      detailTissueNotHas: [],
      params: {
        biosample: [],
        bioproject: [],
        gsm_acc: [],
        gse_acc: [],
        breed: [],
        gender: [],
        main_tissues: [],
        detail_tissue: [],
        cell_stage: [],
        age: [],
        infect: [],
        // design: []
      }
    };
  },
  created() {
    //获取基因列表
    let params = {
      symbol: "", //按基因symbol搜索
      page: this.genePage,
      limit: this.geneLimit,
    };
    listDetail(params).then((res) => {
      this.genesArr = res.data.data;
      this.geneTotal = res.data.totalNum;
      this.spinning = false;
    });
  },
  mounted() {
    this.getField();

    //获取 Sample Feature Select
    this.getSampleFields();
  },
  methods: {
    async getField() {
      const res = await fetchFieldValues();
      // 删除不参与勾选的四列
      delete res.data.biosample;
      delete res.data.bioproject;
      delete res.data.gse_acc;
      delete res.data.gsm_acc;
      delete res.data.design;

      this.fieldObj = res.data;
      // detail_tissue里不该包含的 main_tissues 的值
      this.detailTissueNotHas = res.data.main_tissues.map(item => item.main_tissue);
    },
    getTreeData(arr, type) {
      if (type === "main_tissues") {
        return arr.map(item => ({
              title: item.main_tissue,
              key: item.main_tissue,
              children: item.detail_tissues.map(t => ({title: t, key: t})),
            })
        )
      }

      return arr.map(t => ({title: t, key: t}));
    },
    async getSampleFields() {
      const res = await fetchSampleFields();

      this.groupSpin = false;
      this.parameterFiledArr = res.data;
    },

    /* 下面都是以前的 */
    geneKeydown(val) {
      this.searchValue = val;
      this.genePage = 1;
      this.spinning = true;
      //获取基因列表
      let params = {
        symbol: val, //按基因symbol搜索
        page: this.genePage,
        limit: this.geneLimit,
      };
      listDetail(params).then((res) => {
        this.genesArr = res.data.data;
        this.geneTotal = res.data.totalNum;
        this.spinning = false;
      });
    },
    //下拉框下滑事件
    genesPopupScroll(e) {
      const {target} = e;
      if (
          target.scrollTop + target.offsetHeight === target.scrollHeight &&
          this.genePage * this.geneLimit < this.geneTotal
      ) {
        this.spinning = true;
        this.genePage++;
        let params = {
          symbol: "", //按基因symbol搜索
          page: this.genePage + 1,
          limit: this.geneLimit,
        };
        listDetail(params).then((res) => {
          this.genesArr.push(...res.data.data);
          this.spinning = false;
        });
      }
    },
    geneChange(value, option) {
      this.geneOption = {
        symbol: option.data.key,
        ensemble_id: value,
      };
    },
    resetSampleData() {
      this.parameterFiled = "";
      this.genes = "";
    },
    query() {
      if (this.genes === "") {
        this.$notification["error"]({
          message: "请选择基因",
        });
        return;
      }
      if (this.parameterFiled === "") {
        this.$notification["error"]({
          message: "请选择分组信息",
        });
        return;
      }

      this.queryLoading = true;

      this.params.detail_tissue = this.params.main_tissues.filter(item => this.detailTissueNotHas.indexOf(item) === -1);
      const currentSearchParams = JSON.parse(JSON.stringify(this.params));
      delete currentSearchParams.main_tissues;
      const data = {
        genes: this.genes,
        sample_pms: currentSearchParams,
        meta: {
          groupFiled: this.parameterFiled,
          //groupFiledValue: this.parameterFiledValueObj,
          gene: this.geneOption,
        },
        email: "",
      };

      submit_ea(data).then((res) => {
        if (res.code === 1) {
          this.$notification.error({
            key: "query",
            message: "任务创建失败",
            description: "No Sample found",
          });

          this.queryLoading = false;
          return;
        }

        if (res.code !== 0) {
          this.$notification.error({
            key: "query",
            message: "任务创建失败",
            description: "基因表达量任务创建失败，请重试！",
          });

          this.queryLoading = false;
          return;
        }

        const storageInfo = ["基因表达量", res.uid, data.meta, data.sample_pms];

        syncTaskListStorage(...storageInfo);

        //设置需要轮询结果的id
        waitResponseTask(...storageInfo);

        this.$notification.success({
          key: "query",
          message: "基因表达量任务已添加",
          description: "基因表达量任务已加入任务队列，请查看右下角Task",
        });

        Bus.$emit("pollingInterval");

        this.resetSampleData();
        this.queryLoading = false;
      }).catch(() => {
        this.queryLoading = false;
      });
    },
  }
};
</script>

<style scoped lang="scss">
#singleGene {
  background: #F9F9FB;
  padding: 1.4rem 8rem;

  .container {
    background: #fff;
    padding: 2rem;

    ::v-deep .ant-collapse {
      background: #fff;

      &-item {
        background: #F9F9FB;
        margin-bottom: 24px;
        border: 0;
        overflow: hidden;

        .ant-collapse-header {
          font-size: 19px;
          font-weight: 600;
        }

        .ant-collapse-content {
          background: #fff;
          margin: 0.3rem 0.65rem 0.65rem 0.65rem;
          //max-height: 10rem;
          //overflow: auto;
        }
      }
    }

    .geneSelect {
      width: 100%;
      height: 67px;

      ::v-deep .ant-select-selection {
        height: 67px;
        background: #FFFFFF;
        border-radius: 35px 35px 35px 35px;
        opacity: 1;
        border: 1px solid #E6E6E6;

        .ant-select-selection__rendered {
          height: 67px;
          line-height: 65px;
          font-size: 19px;
          font-weight: 400;
          color: #333333;

          .ant-select-selection__placeholder {
            top: 45% !important;
            left: 10px;
          }

          .ant-select-selection-selected-value {
            margin-left: 10px;
          }
        }

        .ant-select-selection__clear {
          width: 27px;
          height: 27px;

          .anticon {
            width: 27px;
            height: 27px;

            svg {
              position: relative;
              bottom: 8px;
              right: 10px;
              width: 27px;
              height: 27px;
            }
          }
        }
      }
    }

    ::v-deep .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
      border-radius: 35px 35px 0 0;
      border-bottom: 0 !important;
    }

    .geneSelectSpin {
      ::v-deep .ant-spin-container {
        & > div:last-child {
          position: relative !important;
        }
      }
    }

    ::v-deep .geneSelectDrop {
      position: relative;
      top: -2px !important;
      border: 2px solid #097F35;
      border-top: 0 !important;
      border-radius: 0 0 30px 30px !important;

      .ant-select-dropdown-content {
        margin-bottom: 20px;
      }

      .ant-select-dropdown-menu-item {
        padding: 0 1.5rem;
        height: 50px;
        line-height: 50px;
        font-size: 19px;
        font-weight: 400;
        color: #333333;
      }
    }

    & > .btn {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 2rem 0 1.5rem 0;

      .baseBtn {
        width: 133px;
        border-radius: 133px 133px 133px 133px !important;
      }
    }
  }
}
</style>